import { ChainId } from '@pancakeswap-libs/sdk'
import request from '../utils/http';

export default function MenuList() {
  return request({
    url: '/bxh/api/main/menu/list',
    method: 'post',
    data: {chainId: ChainId.MAINNET},
    code: "v1"
  })
}