import axios from 'axios'
import getHeader from './header'

const baseURL = process.env.REACT_APP_BASE_APIURL;

// axios.defaults.timeout = 30000;
// axios.defaults.retry = 1;
// axios.defaults.retryDelay = 1000;

// const CancelToken = axios.CancelToken;

// 创建axios实例
const service = axios.create({
  baseURL: baseURL, // api 的 base_url
  timeout: 30000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    // 这个是 取消重点
    // config.cancelToken = new CancelToken((cancel) => {
    //  CancelStore._axiosPromiseCancel.push(cancel);
    // });
    // let url = config.url.replace(config.baseURL,'');
    const code = config.code;
    config.headers = getHeader(code) // 让每个请求携带自定义签名
    return config
  },
  error => {
    console.log(error) // for debug 11
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    if(response.status===200){
      const res = response.data;
      if (res.success === true) {
        return res.body.data
      }
      return Promise.reject('error')
    }
    return Promise.reject(response.data.message);
  },
  error => {
    console.log('err', error) // for debug
    // if (axios.isCancel(error)) {
    //   // 为了终结promise链 就是实际请求 不会走到.catch(rej=>{});这样就不会触发错误提示之类了。
    //   return new Promise(() => {});
    // } else {
    return Promise.reject(error)
    // }
  }
)

export default service
