import React, { useContext, useCallback, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { LanguageContext } from '../../hooks/LanguageContext'
import { ZHHK, EN } from '../../constants/localisation/languageCodes'
import { getNoticeUrl, getLangURLWithURL } from '../../utils/linkHelper'
import { AppState } from '../../state'

interface SwithProps {
  className?: string
}
const More: React.FC<SwithProps> = props => {
    const { t } = useTranslation()
    const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
    const [mobileMenu, setMobileMenu] = useState(false)
    const [mobileMenuIndex, setMobileMenuIndex] = useState(0)
    const menuList = useSelector((state: AppState) => state.menu.menuList)
    const stopScroll = useCallback((e)=>{
        e.stopPropagation();
        e.preventDefault();
    },[]);
    const showPop = useCallback(()=>{
        window.addEventListener("touchmove", stopScroll, {passive: false });
        document.body.style.overflow = 'hidden';
    },[stopScroll]);
    const closePop = useCallback(()=>{
        window.removeEventListener('touchmove', stopScroll);
        document.body.style.overflow = 'auto';
    },[stopScroll]);
    const handleMobileMenu = useCallback(() => {
        if (mobileMenu) {
            closePop()
        }else{
            showPop()
        }
        setMobileMenu(!mobileMenu)
    }, [mobileMenu,setMobileMenu,showPop,closePop])
    const handleDismissMobileMenu = useCallback(() => {
        closePop()
        setMobileMenu(false)
    }, [setMobileMenu,closePop])
    const handleAnno = useCallback(()=>{
        window.open(getNoticeUrl(selectedLanguage));
    },[selectedLanguage]);
    const contactAddress = useCallback((address)=>{
        window.open(address);
    },[]);
    const openUrl = useCallback((item) => {
      let url = item.params
      if(!url||url===''){
        url = item.href;
      }
      window.open(getLangURLWithURL(selectedLanguage,url),item.target);
    }, [selectedLanguage])
    return (
        <>
          <MenuWarpper onClick={handleMobileMenu}>
            <img style={{width:"20px",height:"20px"}} src={mobileMenu?require('../../assets/bxh/tabnavClose.png').default:require('../../assets/bxh/tabnav.png').default} alt=""/>
          </MenuWarpper>
          <Modal style={{display:mobileMenu?'block':'none'}}>
            <ModalBg onClick={handleDismissMobileMenu}/>
            <ModalContent>
              <Line/>
              {menuList&&menuList.boot&&menuList.boot.map((item,index)=>{
                return (
                  <div key={item.id}>
                    <Item>
                      <ItemTitleRow onClick={()=>setMobileMenuIndex(mobileMenuIndex===index?-1:index)}>
                        <ItemTitle>
                          <img src={require(`../../assets/bxh/menu_${index}.png`).default} alt=""/>
                          <div>{item.title}</div>
                        </ItemTitle>
                        <ItemArrow className={mobileMenuIndex===index?'active':''} src={require('../../assets/bxh/arrow_down.png').default} alt=""/>
                      </ItemTitleRow>
                      {mobileMenuIndex===index&&
                        <ItemContent>
                          {item.nodeList&&item.nodeList.map(linkItem=>(
                            linkItem.params.indexOf('/')===0?
                            <StyledNavLink to={linkItem.params} key={linkItem.id}>{linkItem.title}</StyledNavLink>
                            :
                            <div onClick={()=>openUrl(linkItem)} key={linkItem.id}>{linkItem.title}</div>
                          ))}
                        </ItemContent>
                      }
                    </Item>
                    <Line/>
                  </div>
                )
              })}
              <LanguageRow onClick={() => {setSelectedLanguage(selectedLanguage?.code===ZHHK.code?EN:ZHHK)}}>
                <img src={require('../../assets/bxh/menu_3.png').default} alt="" />
                <span>{selectedLanguage?.code===ZHHK.code?EN.language:ZHHK.language}</span>
              </LanguageRow>
            </ModalContent>
          </Modal>
          {/* <MenuWarpper onClick={handleAnno}>
            <img style={{width:"19px",height:"19px"}} src={require('../../assets/bxh/gonggao.png').default} alt=""/>
          </MenuWarpper>
          <MenuWarpper onClick={handleMobileMenu}>
              <img style={{width:"16px",height:"15px"}} src={mobileMenu?require('../../assets/bxh/tabnavClose.png').default:require('../../assets/bxh/tabnav.png').default} alt=""/>
          </MenuWarpper>
          <Modal style={{display:mobileMenu?'block':'none'}}>
              <ModalBg onClick={handleDismissMobileMenu}/>
              <ModalContent>
              <button type="button" onClick={()=>{contactAddress('https://github.com/BXHash/contracts')}}>
                  <img src={require('../../assets/bxh/menu_1.png').default} alt="" /> 
                  GitHub
              </button>
              <button type="button" onClick={()=>{contactAddress('https://twitter.com/BXH_Blockchain')}}>
                  <img src={require('../../assets/bxh/menu_2.png').default} alt="" />
                  Twitter
              </button>
              {/~ <span onClick={()=>{contactAddress('https://bxh-blockchain.medium.com')}}>Medium</span> ~/}
              <button type="button" onClick={()=>{contactAddress('https://t.me/BXH_global')}}>
                  <img src={require('../../assets/bxh/menu_3.png').default} alt="" />
                  Telegram
              </button>
              <Line/>
              <button type="button" onClick={()=>{
                  // setTimeout(()=>{                            
                  //   const launcher = document.querySelector("#launcher") as HTMLFrameElement
                  //   console.log('launcher',launcher)
                  //   if(launcher&&'contentWindow' in launcher){
                  //     const contentWindow = launcher.contentWindow as Window
                  //     if(contentWindow){
                  //       contentWindow.document.querySelector('button')!.click()
                  //     }
                  //   }
                  // },100)
                  const url = selectedLanguage?.code===ZHHK.code?'https://bxh.gitbook.io/bxh/contact':'https://bxh.gitbook.io/bxh/v/english';
                  window.open(url);
              }}>
                  <img src={require('../../assets/bxh/menu_5.png').default} alt="" />
                  {t('BXH.contactUs')}
              </button>
              <button type="button" onClick={() => {setSelectedLanguage(selectedLanguage?.code===ZHHK.code?EN:ZHHK)}}>
                  <img src={require('../../assets/bxh/menu_4.png').default} alt="" />
                  {selectedLanguage?.code===ZHHK.code?EN.language:ZHHK.language}
              </button>
              </ModalContent>
          </Modal> */}
        </>
    )
}

const MenuWarpper = styled.div`
padding: 8px 0 8px 8px;
img {
  vertical-align: middle;
};
`
const Modal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 65px;
  display: none;
  z-index: 50;
`
const ModalBg = styled.div`
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
  background: #000;
`
const ModalContent = styled.div`
position: absolute;
top: 0;
left: 0;
right: 0;
padding: 0 10px;
background: ${props => props.theme.colors.background};
font-size: 18px;
font-weight: bolder;
`
const Line = styled.div`
height: 1px;
background: ${props => props.theme.colors.borderColor};
`
const Item = styled.div`
color: ${props => props.theme.colors.text};
padding: 15px 0;
`
const ItemTitleRow = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
`
const ItemArrow = styled.img`
&.active {
  transform: rotateZ(180deg);
}
`
const ItemTitle = styled.div`
display: flex;
flex-direction: row;
column-gap: 10px;
align-items: center;
img {
  width: 20px;
}
`
const ItemContent = styled.div`
margin-top: 10px;
padding: 0 30px;
font-size: 16px;
font-weight: normal;
&>div {
  padding: 8px 0;
  color: ${props => props.theme.colors.textDisabled};
};
&>a {
  padding: 8px 0;
  color: ${props => props.theme.colors.textDisabled};
};
`
const LanguageRow = styled.div`
padding: 15px 0;
display: flex;
flex-direction: row;
column-gap: 10px;
align-items: center;
color: ${props => props.theme.colors.text};
img {
  width: 20px;
}
`
const activeClassName = 'ACTIVE'
const StyledNavLink = styled(NavLink).attrs({
    activeClassName
})`
display: block;
`

export default More
