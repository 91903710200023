import { Web3Provider } from '@ethersproject/providers'
import { ChainId } from '@pancakeswap-libs/sdk'
import { connectorLocalStorageKey, ConnectorNames } from '@pancakeswap-libs/uikit'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
// eslint-disable-next-line import/no-unresolved
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { useEffect, useState, useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import { injected, okConnector } from '../connectors'
import { NetworkContextName } from '../constants'

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & { chainId?: ChainId } {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
  return context.active ? context : contextNetwork
}

export function useEagerConnect() {
  const { activate, active } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false)

  const connectInjected = useCallback(() => {
    injected.isAuthorized().then((isAuthorized) => {
      // const hasSignedIn = window.localStorage.getItem(connectorLocalStorageKey)
      // if (isAuthorized && hasSignedIn) {
      //   activate(injected, undefined, true).catch(() => {
      //     setTried(true)
      //   })
      // } else if (isMobile && window.ethereum && hasSignedIn) {
      //   activate(injected, undefined, true).catch(() => {
      //     setTried(true)
      //   })
      // } else {
      //   setTried(true)
      // }
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else if (isMobile && window.ethereum) {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        setTried(true)
      }
    })
  },[activate])
  const connectOK = useCallback(() => {
    okConnector.isAuthorized().then((authorized) => {
      if (authorized) {
        activate(okConnector, undefined, true).catch(() => {
          setTried(true)
        })
      } else if (isMobile && window.ethereum) {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        connectInjected()
      }
    })
  },[activate,connectInjected])

  // const checkInjectedChain = useCallback(() => {
  //   if(window.ethereum){
  //     if(window.ethereum.chainId==='0x42'){
  //       return
  //     }
  //     window.ethereum.request({
  //       method: 'wallet_addEthereumChain',
  //       params: [
  //           {
  //               chainId: '0x42',
  //               chainName: 'OKExChain',
  //               nativeCurrency: {
  //                   name: 'OKT',
  //                   symbol: 'OKT',
  //                   decimals: 18,
  //               },
  //               rpcUrls: ['https://exchainrpc.okex.org'],
  //               blockExplorerUrls: ['https://www.oklink.com/okexchain'],
  //           },
  //       ],
  //     }).then(() => {
  //       console.log('OKEX网络切换成功')
  //       connectInjected()
  //     }).catch((e) => {
  //       console.log('==',e)
  //     })
  //   }
  // },[connectInjected])
  // const checkOKChain = useCallback(() => {
  //   if(window.okexchain){
  //     if(window.okexchain.chainId==='0x42'){
  //       return
  //     }
  //     window.okexchain.request({
  //       method: 'wallet_addEthereumChain',
  //       params: [
  //           {
  //               chainId: '0x42',
  //               chainName: 'OKExChain',
  //               nativeCurrency: {
  //                   name: 'OKT',
  //                   symbol: 'OKT',
  //                   decimals: 18,
  //               },
  //               rpcUrls: ['https://exchainrpc.okex.org'],
  //               blockExplorerUrls: ['https://www.oklink.com/okexchain'],
  //           },
  //       ],
  //     }).then(() => {
  //       console.log('OKEX网络切换成功')
  //       connectOK()
  //     }).catch((e) => {
  //       console.log('==',e)
  //     })
  //   }
  // },[connectOK])

  useEffect(() => {
    setTimeout(() => {
      const hasSignedIn = window.localStorage.getItem(connectorLocalStorageKey)
      if(hasSignedIn===ConnectorNames.OEC){
        connectOK()
      }else{
        connectInjected()
      }
    }, 250);
  }, [activate,connectOK,connectInjected]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3ReactCore() // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    const { ethereum } = window

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch((e) => {
          console.error('Failed to activate after chain changed', e)
        })
      }

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch((e) => {
            console.error('Failed to activate after accounts changed', e)
          })
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate])
}
