import React, { useContext, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { transparentize } from 'polished'
import { useSelector } from 'react-redux'
import { LanguageContext } from '../../hooks/LanguageContext'
import { getLangURLWithURL,getNoticeUrl } from '../../utils/linkHelper'
import { ZHHK } from '../../constants/localisation/languageCodes'
import { AppState } from '../../state'

const FooterPC: React.FC = () => {
  const { t } = useTranslation()
  const { selectedLanguage } = useContext(LanguageContext)
  const menuList = useSelector((state: AppState) => state.menu.menuList)
  const contactUrl = selectedLanguage?.code===ZHHK.code?'https://bxh.gitbook.io/bxh/contact':'https://bxh.gitbook.io/bxh/v/english';
  const openUrl = useCallback((item) => {
    let url = item.params
    if(!url||url===''){
      url = item.href;
    }
    window.open(getLangURLWithURL(selectedLanguage,url),item.target);
  }, [selectedLanguage])
  return (
    <StyledFooter>
      <Content>
        <Info>
            <Logo href={getLangURLWithURL(selectedLanguage,"https://app.bxh.com/#/")}>
              <img src="https://bxh-images.s3.ap-east-1.amazonaws.com/images/logo-bsc.png" alt="Logo" />
            </Logo>
            <Title>{t('BXH.footercolt')}</Title>
            <Social>
              <a href='https://twitter.com/BXH_Blockchain' rel='noreferrer' target='_blank'>
                <img src={require('../../assets/bxh/twitter.png').default} alt="" />
              </a>
              <a href='https://t.me/BXH_global' rel='noreferrer' target='_blank'>
                <img src={require('../../assets/bxh/telegram.png').default} alt="" />
              </a>
            </Social>
            <AllRight>© 2022 BXH. All righs reserved.</AllRight>
        </Info>
        <Right>
        {menuList&&menuList.boot&&menuList.boot.map(item=>(
          <List key={item.id}>
            <ListTitle>{item.title}</ListTitle>
            {item.nodeList&&item.nodeList.map(linkItem=>(
              linkItem.params.indexOf('/')===0?
              <StyledNavLink to={linkItem.params} key={linkItem.id}>{linkItem.title}</StyledNavLink>
              :
              <ItemRow onClick={()=>openUrl(linkItem)} key={linkItem.id}>{linkItem.title}</ItemRow>
            ))}
          </List>
        ))}
        </Right>
        {/* <List>
          <div>{t('BXH.About')}</div>
          <a href='https://bxh.gitbook.io/bxh/' rel='noreferrer' target='_blank'>{t('BXH.bxh')}</a>
          <a href='https://bxh.gitbook.io/bxh/whitepaper' rel='noreferrer' target='_blank'>{t('BXH.whitepaper')}</a>
          <a href='https://weibo.com/u/7610664942' rel='noreferrer' target='_blank'>{t('BXH.blog')}</a>
          <a href={contactUrl} rel='noreferrer' target='_blank'>{t('BXH.contactUs')}</a>
        </List>
        <List>
          <div>{t('BXH.file')}</div>
          <a href={selectedLanguage.code===ZHHK.code?'https://bxh.gitbook.io/bxh/help':'https://bxh.gitbook.io/bxh/v/english/help'} rel='noreferrer' target='_blank'>{t('BXH.help')}</a>
          <a href={getNoticeUrl(selectedLanguage)} rel='noreferrer' target='_blank'>{t('BXH.news')}</a>
          <a href='https://github.com/BXHash/contracts' rel='noreferrer' target='_blank'>GitHub</a>
        </List>
        <List>
          <div>{t('BXH.navigation')}</div>
          <StyledNavLink to='/swap'>{t('BXH.swap')}</StyledNavLink>
          <a href={getLangURLWithURL(selectedLanguage,'https://app.bxh.com/#/liquidity')}>{t('BXH.fluidMining')}</a>
          <a href='https://okinfo.bxh.com' rel='noreferrer' target='_blank'>{t('BXH.market')}</a>
        </List> */}
      </Content>
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
margin-top: 30px;
width: 100%;
border-top: 1px solid ${({ theme }) => transparentize(0.5, theme.colors.borderColor)};
color: ${({ theme }) => theme.colors.text};
padding: 30px 0;
justify-content: center;
display: none;
${({ theme }) => theme.mediaQueries.sm} {
  display: flex;
};
`
const Content = styled.div`
min-width: 1000px;
width: 1090px;
display: flex;
line-height: 1.43;
justify-content: space-between;
`
const Info = styled.div`
width: 400px;
margin-right: 50px;
`
const Right = styled.div`
display: flex;
flex-direction: row;
`
const Logo = styled.a`
img {
  width: 136px;
  cursor: pointer;
}
`
const Title = styled.div`
color: ${({ theme }) => theme.colors.textSubtle};
opacity: 0.6;
font-size: 14px;
margin-top: 10px;
`
const Social = styled.div`
margin: 10px 0;
img {
  width: 20px;
  margin-right: 8px;
  cursor: pointer;
}
`
const AllRight = styled.div`
font-size: 14px;
opacity: 0.5;
`
const List = styled.div`
margin-left: 100px;
margin-top: 20px;
display: flex;
flex-direction: column;
@media (max-width: 1080px) {
  margin-left: 50px;
}
`
const ListTitle = styled.div`
color: ${({ theme }) => theme.colors.text};
margin: 0px;
font-size: 16px;
font-weight: 500;
margin-bottom: 20px;
`
const ItemRow = styled.div`
font-size: 14px;
margin-bottom: 15px;
font-weight: 500;
cursor: pointer;
color: ${({ theme }) => theme.colors.textDisabled};
&:hover {
  color: ${({ theme }) => theme.colors.primary};
};
`
const StyledNavLink = styled(NavLink).attrs({})`
font-size: 14px;
margin-bottom: 15px;
font-weight: 500;
color: ${({ theme }) => theme.colors.textDisabled};
&:hover {
  color: ${({ theme }) => theme.colors.primary};
};
`

export default FooterPC;
