import React, { useContext, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { NavLink,useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TranslateString } from '../../utils/translateTextHelpers'
import { LanguageContext } from '../../hooks/LanguageContext'
import { getLangURLWithURL } from '../../utils/linkHelper'
import { AppState } from '../../state'

const Footer: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation()
  const { selectedLanguage } = useContext(LanguageContext)
  const menuList = useSelector((state: AppState) => state.menu.menuList)
  const openUrl = useCallback((item) => {
    let url = item.params
    if(!url||url===''){
      url = item.href;
    }
    window.open(getLangURLWithURL(selectedLanguage,url),item.target);
  }, [selectedLanguage])
    return (
        <StyledFooter>
            {menuList&&menuList.info_menu&&menuList.info_menu.map((item) => (
              <div key={item.id}>
                {
                  item.params.indexOf('/')===0?
                  <StyledNavLink to={item.params} isActive={()=>pathname===item.params} key={item.id}>
                    <img src={item.params===pathname?item.imageUrlSelect:item.imageUrl} alt="" />
                    <span>{item.title}</span>
                  </StyledNavLink>
                  :
                  <Item onClick={()=>openUrl(item)} key={item.id}>
                    <img src={item.params==='/swap'?item.imageUrlSelect:item.imageUrl} alt="" />
                    <Text className={item.params==='/swap'?'selected':''}>{item.title}</Text>
                  </Item>
                }
              </div>
            ))}
            {/* <div>
                <a href={getLangURLWithURL(selectedLanguage,"https://app.bxh.com/#/")}>
                    <img src={require('../../assets/bxh/footer_1.png').default} alt="" />
                    <span>BXH</span>
                </a>
            </div>
            <div>
                <img src={require('../../assets/bxh/footer_2.png').default} alt="" />
                <Selected>{t('BXH.swap')}</Selected>
            </div>
            <div>
                <a href={getLangURLWithURL(selectedLanguage,"https://app.bxh.com/#/liquidity")}>
                    <img src={require('../../assets/bxh/footer_3.png').default} alt="" />
                    <span>{t('BXH.pool')}</span>
                </a>
            </div>
            <div>
                <a href={getLangURLWithURL(selectedLanguage,"https://app.bxh.com/#/bridge")}>
                    <img src={require('../../assets/bxh/footer_6.png').default} alt="" />
                    <span>{t('BXH.MBridge')}</span>
                </a>
            </div> */}
            {/* <div>
                <a href={getLangURLWithURL(selectedLanguage,"https://app.bxh.com/#/dao")}>
                    <img src={require('../../assets/bxh/footer_4.png').default} alt="" />
                    <span>DAO</span>
                </a>
            </div> */}
            {/* <div>
                <a href={getLangURLWithURL(selectedLanguage,"https://okinfo.bxh.com")} rel="noreferrer" target="_blank">
                    <img src={require('../../assets/bxh/footer_5.png').default} alt="" />
                    <span>{t('BXH.market')}</span>
                </a>
            </div> */}
        </StyledFooter>
    )
}

export const StyledFooter = styled.div`
  display: flex;
  left: 0px;
  width: 100%;
  bottom: 0px;
  padding: 12px 0;
  z-index: 2;
  position: fixed;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  box-shadow: 0 -3px 5px 0 rgb(135 132 132 / 5%);
  @media (min-width: 600px) {
    display: none;
  }
  &>div {
    font-style: inherit;
    display: block;
    width: 100%;
    text-align: center;
  }
  img {
    width: 16px;
  }
  span {
    display: block;
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
  }
  a {
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
  }
`
const Item = styled.a``
export const Text = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.text};
  &.selected {
    color: ${({ theme }) => theme.colors.primary};
  };
`
const activeClassName = 'ACTIVE'
const StyledNavLink = styled(NavLink).attrs({
    activeClassName
})`
color: ${({ theme }) => theme.colors.text};
span {
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
};
&.${activeClassName} {
    color: ${({ theme }) => theme.colors.primary};
}
`

export default Footer