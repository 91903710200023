import React, { useContext, useCallback } from 'react'
import styled from 'styled-components'
import { LanguageContext } from '../../hooks/LanguageContext'
import { ZHHK, EN } from '../../constants/localisation/languageCodes'

interface SwithProps {
  className?: string
}
const AccountLink: React.FC<SwithProps> = props => {
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const img = selectedLanguage?.code===ZHHK.code?require('../../assets/bxh/en.png'):require('../../assets/bxh/zh-HK.png');
  // const textStr = selectedLanguage?.code===ZHHK.code?EN.language:ZHHK.language;
  return (
    <StyledButton onClick={() => {setSelectedLanguage(selectedLanguage?.code===ZHHK.code?EN:ZHHK)}}>
      <img src={img.default} alt="" />
      {/* <div className="text">{textStr}</div> */}
      {/* <Modal className="modal">
        <Button
          className={selectedLanguage?.code === EN.code ? 'active' : 'unactive'}
          onClick={() => {
            handleSetSelectedLanguage(EN)
          }}
        >
          {EN.language}
        </Button>
        <Button
          className={selectedLanguage?.code === ZHHK.code ? 'active' : 'unactive'}
          onClick={() => {
            handleSetSelectedLanguage(ZHHK)
          }}
        >
          {ZHHK.language}
        </Button>
      </Modal> */}
    </StyledButton>
  )
}

const StyledButton = styled.div`
  margin-left: 20px;
  position: relative;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  align-items: center;
  padding: 0 8px;
  border-radius: 20px;
  display: none;
  flex-direction: row;
  justify-content: center;
  background: ${props => props.theme.colors.backgroundDisabled};
  img {
    width: 24px;
    height: 24px;
  }
  .text {
    margin-left: 8px;
    color: ${props => props.theme.colors.text};
    font-size: 16px;
    font-weight: 400;
  }
  &:hover {
    background: ${props => props.theme.colors.backgroundDisabled};
    .modal {
      display: block;
    }
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
  };
`
const Button = styled.div`
  margin-bottom: 24px;
  text-align: center;
  border-radius: 8px;
  font-weight: bolder;
  color: ${props => props.theme.colors.primary};
  &.unactive {
    color: ${props => props.theme.colors.text};
  }
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`
const Modal = styled.div`
  position: absolute;
  left: 0;
  top: 56px;
  width: 100px;
  display: none;
  border-radius: 12px;
  padding: 24px;
  padding-left: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
  background: #262946;
  box-shadow: 0px 4px 20px rgba(117, 117, 117, 0.1);
  border-radius: 12px;
`

export default AccountLink
