import React, {useCallback, useEffect, useState, useContext} from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import LngSwithForWeb from './LngSwithForWeb'
import { useIsDarkMode } from '../../state/user/hooks'
import Logo from './Logo'
// import menuIcon from '../../assets/images/menu.png'
import AccountButton from './AccountButton'
import { useActiveWeb3React } from '../../hooks'
import { useWalletModalToggle } from '../../state/application/hooks'
import { shortenAddress } from '../../utils'
import { TranslateString } from '../../utils/translateTextHelpers'
import { LanguageContext } from '../../hooks/LanguageContext'
import Chain from './Chain'
import Nav from './Nav'
import More from './More'
import { getLangURLWithURL,getNoticeUrl } from '../../utils/linkHelper'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: relative;
  background:  ${({ theme }) => theme.colors.background};
  z-index: 2;
  ${({ theme }) => theme.mediaQueries.sm} {
    position: fixed;
    width: calc(100%);
    background:  ${({ theme }) => theme.colors.backgroundAlt};
  }
`
const Menu = styled.div`
  display: none;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
    width: 30px;
    margin-right: 10px;
    img {
      width: 100%;
      display: block;
    }
  };
`
const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 320px) {
    width: 20%;
  }
`

const HeaderControlsMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledTopBarInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  height: 65px;
  max-width: auto;
  min-width: auto;
  
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 80%;
    padding: 0;
    max-width: 1800px;
    min-width: 1300px;
  };
`
const StyledAccountButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  div: {
    margin-right: 20px;
  };
  ${({ theme }) => theme.mediaQueries.sm} {
    .lng-switch, .price {
      display: none;
    }
  };
`
const Price = styled.div`
  color: ${props => props.theme.colors.primary};
  margin-right: 24px;
  font-weight: bolder;
`
const Button = styled.div`
  margin-left: 10px;
  align-items: center;
  background: ${props => props.theme.colors.background};
  border-radius: 12px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #2EBC84;
  padding: 0 10px;
  color: #2EBC84;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  height: 24px;
  font-weight: 700;
  justify-content: center;
  outline: none;
  width: 100%;
  &:hover {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 0.2);
  }
`
const Account = styled.div`
  height: 24px;
  border-radius: 12px;
  margin-left: 10px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  justify-items: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  color: ${props => props.theme.colors.text};
  background: ${props => props.theme.colors.background};
  .wallet {
    margin-left: 5px;
    width: 15px;
  }
`

export default function Header() {
  const isDark = useIsDarkMode()
  const { account } = useActiveWeb3React()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const toggleWalletModal = useWalletModalToggle()
  return (
    <HeaderFrame>
      <StyledTopBarInner>
        <HeaderElement>
          {/* <Menu onClick={handlePresentMobileMenu}>
            <img src={menuIcon} alt="menu" />
          </Menu> */}
          <Title href={getLangURLWithURL(selectedLanguage,"https://app.bxh.com/#/")}>
            <Logo isDark={isDark} />
          </Title>
          {/* {!isMobile && <Nav />} */}
        </HeaderElement>
        {isMobile && (
          <HeaderControlsMobile>
            <Chain/>
            <AccountButton />
            {/* {!account ? (
                <Button onClick={toggleWalletModal}>Unlock Wallet</Button>
              ):(
                <Account>
                  <span className="text">{shortenAddress(account)}</span>
                  <img src={require('../../assets/images/tesol.png')} alt="" className="wallet" />
                </Account>
              )
            } */}
            <More/>
          </HeaderControlsMobile>
        )}
        {!isMobile && (
          <HeaderControls>
            <HeaderElement>
              <StyledAccountButtonWrapper>
                {/* <Web3Status /> */}
                {/* <Price className="number price">1BXH=${bxhPrice.toFixed(3)}</Price> */}
                <Nav />
                <Chain/>
                <AccountButton />
                {/* <Settings /> */}
                <LngSwithForWeb />
              </StyledAccountButtonWrapper>
            </HeaderElement>
          </HeaderControls>
        )}
        {/* <MobileMenu onDismiss={handleDismissMobileMenu} visible={mobileMenu} /> */}
      </StyledTopBarInner>
    </HeaderFrame>
  )
}
