// import $md5 from 'js-md5'

export default function getHeader(code) {
  const timestamp = new Date() * 1;
  let lang = localStorage.getItem("i18nextLng") ?? "en_US";
  lang = lang.indexOf('zh') === 0 ? "zh_CN" : 'en_US';
//   let token = sessionStorage.getItem('user_token') === null? "" : sessionStorage.getItem('user_token');
  const source = 'web';
//   let sign =  $md5(source + code + timestamp + token);
//   return {'Content-Type': 'application/json; charset=UTF-8', 'token': token,'timestamp': timestamp, 'code': code, 'sign':sign, 'source':source };
  return {'Content-Type': 'application/json; charset=UTF-8', 'timestamp': timestamp, 'code': code, 'source': source, "lang": lang};
}