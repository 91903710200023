import React from 'react'
import styled from 'styled-components'
// import logoDark from '../../../assets/images/logo-pipi-dark.png'
// import logoLight from '../../../assets/images/logo-pipi-light.png'
// import logoText from '../../../assets/images/logo_text.png'

interface LogoProps {
  isDark: boolean
}
const Logo: React.FC<LogoProps> = ({ isDark }) => {
  return (
    <StyledLogo>
      {/* <img src={isDark ? logoDark : logoLight} alt="logo" />
      <img src={logoText} alt="text" className="text" /> */}
      <img src="https://bxh-images.s3.ap-east-1.amazonaws.com/images/logo-bsc.png" alt="logo" />
    </StyledLogo>
  )
}

const StyledLogo = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
  img {
    width: 76px;
  }
  .text {
    margin-left: 6px;
    height: 23px;
    width: auto;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    .text {
      display: none
    }
  };
`

export default Logo
