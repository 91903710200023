
export function numberDecimalNoZero(value: number, decimal = 4) {
    const scaleP = 10**decimal;
    const result = Math.floor(value * scaleP) / scaleP;
    return result;
}

export function numberDecimal(value: number, decimal = 4) {
    return value.toLocaleString(undefined, {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal
    })
}