import { useCurrency } from 'hooks/Tokens'
import { useTradeExactIn } from 'hooks/Trades'
import { tryParseAmount } from 'state/swap/hooks'
import { BXH, USDT } from '../constants'

const useGetBXHBusdLpPrice = () => {
  const inputCurrency = useCurrency(BXH.address)
  const outputCurrency = useCurrency(USDT.address)
  const parsedAmount = tryParseAmount('1', inputCurrency ?? undefined)
  const bestTradeExactIn = useTradeExactIn(parsedAmount, outputCurrency ?? undefined)
  const price = bestTradeExactIn?.executionPrice.toSignificant(6)
  return price ? parseFloat(price) : undefined
}

export default useGetBXHBusdLpPrice
