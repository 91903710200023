import { createReducer } from '@reduxjs/toolkit'
import {
    ApplicationState,
    updateMenuList
} from './actions'

const MENU_LIST_CACHE_KEY = 'menuList'

const initialState: ApplicationState = {
    menuList: getMenuList()
}

export function setMenuList(data) {
    if (data === null || data === undefined) {
        return;
    }
    const menuListStr = JSON.stringify(data);
    localStorage.setItem(MENU_LIST_CACHE_KEY,menuListStr);
}
export function getMenuList(): any {
    const menuListStr = localStorage.getItem(MENU_LIST_CACHE_KEY) ?? "";
    if (menuListStr==="") {
        return {}
    }
    return JSON.parse(menuListStr)
}
  
export default createReducer(initialState, builder =>
    builder
    .addCase(updateMenuList, (state, action) => {
        state.menuList = action.payload.menuList
        setMenuList(state.menuList)
    })
)