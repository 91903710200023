import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { Credentials, StringTranslations } from '@crowdin/crowdin-api-client'
import { LangType, useModal } from '@pancakeswap-libs/uikit'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
// import VersionBar from 'components/VersionBar'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './AddLiquidity/redirects'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import AddLiquidity from './AddLiquidity'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Swap from './Swap'
import Migration from './Migration'
import { RedirectPathToSwapOnly } from './Swap/redirects'
import { EN, ZHHK, allLanguages } from '../constants/localisation/languageCodes'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'
import UseV2ExchangeModal from '../components/UseV2ExchangeModal'

import Menu from '../components/Menu'
import useGetDocumentTitlePrice from '../hooks/useGetDocumentTitlePrice'
import { urlChangeLanguage } from '../utils/linkHelper'
import { AppDispatch } from '../state'
import { updateMenuList } from '../state/menu/actions'
import MenuList from '../api'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  padding-bottom: 80px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 160px;
    padding-bottom: 0;
  };
`

const CACHE_KEY = 'bxhSwapLanguage'

export default function App() {
  const { i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])
  const dispatch = useDispatch<AppDispatch>()
  const apiKey = `${process.env.REACT_APP_CROWDIN_APIKEY}`
  const projectId = parseInt(`${process.env.REACT_APP_CROWDIN_PROJECTID}`)
  const fileId = 36
  const credentials: Credentials = {
    token: apiKey,
  }

  const stringTranslationsApi = new StringTranslations(credentials)

  const [hasSeenModal, setHasSeenModal] = useState(true)
  const [onPresentUseV2ExchangeModal] = useModal(<UseV2ExchangeModal />)

  useEffect(() => {
    const showModal = () => {
      onPresentUseV2ExchangeModal()
      setHasSeenModal(true)
    }
    if (!hasSeenModal) {
      showModal()
    }
  }, [onPresentUseV2ExchangeModal, hasSeenModal])

  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
  }

  useEffect(() => {
    const isChange = urlChangeLanguage(setSelectedLanguage)
    if (!isChange) {
      const storedLangCode = localStorage.getItem(CACHE_KEY)
      if (storedLangCode) {
        const storedLang = getStoredLang(storedLangCode)
        setSelectedLanguage(storedLang)
      } else {
        setSelectedLanguage(EN)
      }
    }
  }, [])

  const fetchTranslationsForSelectedLanguage = async () => {
    stringTranslationsApi
      .listLanguageTranslations(projectId, selectedLanguage.code, undefined, fileId, 200)
      .then((translationApiResponse) => {
        if (translationApiResponse.data.length < 1) {
          setTranslations(['error'])
        } else {
          setTranslations(translationApiResponse.data)
        }
      })
      .then(() => setTranslatedLanguage(selectedLanguage))
      .catch((error) => {
        setTranslations(['error'])
        console.error(error)
      })
  }

  useEffect(() => {
    if (selectedLanguage) {
      fetchTranslationsForSelectedLanguage()
      if('changeLanguage' in i18n){
        i18n.changeLanguage(selectedLanguage.code===ZHHK.code?ZHHK.code:EN.code)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage,i18n])

  useEffect(() => {
    if (selectedLanguage) {
      MenuList().then(data=>{
        const newData = {menuList: {info_menu:[],boot:[],...data}};
        dispatch(updateMenuList(newData));
      })
    }
  }, [dispatch,selectedLanguage])

  const handleLanguageSelect = (langObject: LangType) => {
    setSelectedLanguage(langObject)
    localStorage.setItem(CACHE_KEY, langObject.code)
  }

  useGetDocumentTitlePrice()

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <AppWrapper>
          <LanguageContext.Provider
            value={{
              selectedLanguage,
              setSelectedLanguage: handleLanguageSelect,
              translatedLanguage,
              setTranslatedLanguage,
            }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              {/* <Menu> */}
                <HeaderWrapper>
                  <Header />
                </HeaderWrapper>
                <BodyWrapper>
                  <Popups />
                  <Web3ReactManager>
                    <Switch>
                      <Route exact strict path="/swap" component={Swap} />
                      <Route exact strict path="/find" component={PoolFinder} />
                      <Route exact strict path="/pool" component={Pool} />
                      <Route exact path="/add" component={AddLiquidity} />
                      <Route exact path="/migrate" component={Migration} />
                      <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

                      {/* Redirection: These old routes are still used in the code base */}
                      <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                      <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />

                      <Route component={RedirectPathToSwapOnly} />
                    </Switch>
                  </Web3ReactManager>
                </BodyWrapper>
              {/* </Menu> */}
              {/* <VersionBar /> */}
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
