import React, { useCallback, useState, useContext } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { getLangURLWithURL } from '../../utils/linkHelper'
import { LanguageContext } from '../../hooks/LanguageContext'

const ChainWarpper = styled.div`
    cursor: pointer;
    position: relative;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background: url(/images/arrow_down.png) no-repeat right;
    background-position: right 5px top 8px;
    padding: 5px 8px;
    padding-right: 20px;
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 12px;
    border-radius: 12px;
    ${({ theme }) => theme.mediaQueries.sm} {
        background: url(/images/arrow_down_white.png) no-repeat right;
        background-position: right 15px top 18px;
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.text};
        font-size: 14px;
        font-weight: bold;
        border-radius: 20px;
        line-height: 40px;
        padding: 0 20px;
        padding-right: 30px;
        margin-left: 20px;
        margin-right: 20px;
    };
    &:hover {
        .modal {
            display: block;
        }
    }
`
const ChainModal = styled.div`
    position: absolute;
    right: 0;
    top: 30px;
    bottom: 0;
    z-index: 100;
    display: none;
`
const ChainModalHover = styled.div`
    margin-top: 10px;
    position: absolute;
    top: 0;
    right: -20px;
    ${({ theme }) => theme.mediaQueries.sm} {
        right: -10px;
    };
    &:hover {
        .modal {
            display: block;
        }
    }
`
const ChainModalContent = styled.div`
    margin-top: 0;
    background: ${({ theme }) => theme.colors.backgroundAlt};
    top: 0;
    right: -20px;
    color: ${({ theme }) => theme.colors.text};
    width: 100px;
    padding: 0 10px;
    overflow: hidden;
    font-size: 14px;
    border-radius: 8px;
    box-shadow: 0 3px 8px 0 ${({ theme }) => transparentize(0.6, theme.colors.background)};
    ${({ theme }) => theme.mediaQueries.sm} {
        right: -10px;
        margin-top: 20px;
    };
`
const ChainModalRow = styled.div`
    cursor: pointer;
    font-weight: 500;
    line-height: 20px;
    margin: 10px 0;
    img {
        width: 10px;
    };
    span {
        margin-left: 8px;
    }
`

export default function Chain() {
    const { selectedLanguage } = useContext(LanguageContext)
    const [openChainId, setOpenChainId] = useState(false)
    const onOpenChainID = useCallback(() => {
      setOpenChainId(!openChainId)
    },[openChainId,setOpenChainId])
    const handleDismissChainIDMenu = useCallback(() => {
      setOpenChainId(false)
    }, [setOpenChainId])

    return (
        <ChainWarpper onClick={onOpenChainID}>
            <span>OKC</span>
            <ChainModal className="modal" onClick={handleDismissChainIDMenu}>
                <ChainModalHover>
                    <ChainModalContent>
                        <ChainModalRow onClick={()=>window.open(getLangURLWithURL(selectedLanguage,"https://swap.bxh.com/#/swap"),'_self')}>
                            <img src={require('../../assets/bxh/huobi.png').default} alt="" />
                            <span>HECO</span>
                        </ChainModalRow>
                        <ChainModalRow onClick={()=>window.open(getLangURLWithURL(selectedLanguage,"https://bscswap.bxh.com/#/swap"),'_self')}>
                            <img src={require('../../assets/bxh/bian.png').default} alt="" />
                            <span>BSC</span>
                        </ChainModalRow>
                        <ChainModalRow>
                            <img src={require('../../assets/bxh/oec.png').default} alt="" />
                            <span>OKC</span>
                        </ChainModalRow>
                        <ChainModalRow onClick={()=>window.open(getLangURLWithURL(selectedLanguage,"https://ethswap.bxh.com/#/swap"),'_self')}>
                            <img src={require('../../assets/bxh/eth.png').default} alt="" />
                            <span>ETH</span>
                        </ChainModalRow>
                        <ChainModalRow onClick={()=>window.open(getLangURLWithURL(selectedLanguage,"https://avaxswap.bxh.com/#/swap"),'_self')}>
                            <img src={require('../../assets/bxh/avax.png').default} alt="" />
                            <span>AVAX</span>
                        </ChainModalRow>
                    </ChainModalContent>
                </ChainModalHover>
            </ChainModal>
        </ChainWarpper>
    )
}