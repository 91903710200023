import React, { useContext, useCallback } from 'react'
import { NavLink,useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getLangURLWithURL,getNoticeUrl } from '../../utils/linkHelper'
import { LanguageContext } from '../../hooks/LanguageContext'
import { AppState } from '../../state'

function Nav() {
    const { pathname } = useLocation();
    const { t } = useTranslation()
    const { selectedLanguage } = useContext(LanguageContext)
    const menuList = useSelector((state: AppState) => state.menu.menuList)
    const openUrl = useCallback((item) => {
        let url = item.params
        if(!url||url===''){
            url = item.href;
        }
        window.open(getLangURLWithURL(selectedLanguage,url),item.target);
    }, [selectedLanguage])
    // const openPureUrl = useCallback((url) => {
    //     window.open(url,'_blank');
    // }, [])
    return (
        <Warpper>
            {menuList&&menuList.info_menu&&menuList.info_menu.map((item) => (
                item.params.indexOf('/')===0?
                // <StyledNavLink to={item.params} isActive={()=>pathname===item.params} key={item.id}>{item.title}</StyledNavLink>
                <StyledNavLink to={item.params} isActive={()=>false} key={item.id}>{item.title}</StyledNavLink>
                :
                <Item onClick={()=>openUrl(item)} key={item.id}>{item.title}</Item>
            ))}
            {/* <Item onClick={()=>openUrl("https://app.bxh.com/#/")}>{t('BXH.home')}</Item>
            <StyledNavLink to='/swap' isActive={()=>pathname==='/swap'}>{t('BXH.swap')}</StyledNavLink>
            <StyledNavLink to='/pool' isActive={()=>pathname==='/pool'}>{t('pool')}</StyledNavLink>
            <Item onClick={()=>openUrl("https://app.bxh.com/#/liquidity")}>{t('BXH.fluidMining')}</Item> */}
            {/* <Item onClick={()=>openUrl("https://app.bxh.com/#/loan")}>{t('BXH.loan')}</Item> */}
            {/* <Item onClick={()=>openPureUrl("https://app.bxh.com/#/bridge")}>{t('BXH.Bridge')}</Item>
            <Item onClick={()=>openPureUrl("https://okinfo.bxh.com/")}>{t('BXH.market')}</Item>
            <Item onClick={()=>openPureUrl(getNoticeUrl(selectedLanguage))}>{t('BXH.news')}</Item> */}
        </Warpper>
    )
}

const Warpper = styled.div`
align-items: center;
display: none;
${({ theme }) => theme.mediaQueries.sm} {
  display: flex;
};
`
const Item = styled.div`
color: ${({ theme }) => theme.colors.text};
border: 1px solid rgba(151, 151, 151, 0.15);
cursor: pointer;
height: 40px;
padding: 0 20px;
position: relative;
font-size: 14px;
font-weight: bold;
line-height: 40px;
margin-right: 20px;
border-radius: 20px;
&:hover {
    background: ${({ theme }) => transparentize(0.95, theme.colors.primary)};
}
&:active {
    background: ${({ theme }) => transparentize(0.95, theme.colors.primary)};
}
`
const activeClassName = 'ACTIVE'
const StyledNavLink = styled(NavLink).attrs({
    activeClassName
})`
color: ${({ theme }) => theme.colors.text};
border: 1px solid rgba(151, 151, 151, 0.15);
cursor: pointer;
height: 40px;
padding: 0 20px;
position: relative;
font-size: 14px;
font-weight: bold;
line-height: 40px;
margin-right: 20px;
border-radius: 20px;
&.${activeClassName} {
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
}
&:hover {
    background: ${({ theme }) => transparentize(0.95, theme.colors.primary)};
}
&:active {
    background: ${({ theme }) => transparentize(0.95, theme.colors.primary)};
}
`

export default Nav;
