import React, { useCallback, useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import { isMobile } from 'react-device-detect'
import { useWalletModal } from '@pancakeswap-libs/uikit'
import useGetBXHBusdLpPrice from 'utils/useGetBXHBusdLpPrice'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import { useCurrency } from '../../hooks/Tokens'
import UnlockButton from '../ConnectWalletButton'
import useAuth from '../../hooks/useAuth'
import { BXH } from '../../constants'
import { numberDecimal } from '../../utils/formatNumberDecimal'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useActiveWeb3React } from '../../hooks'
import TranslatedText from '../TranslatedText'
import { shortenAddress, getBscScanLink } from '../../utils'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'

function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

export const CONNECTOR_STORAGE_ID = 'CONNECTOR_STORAGE_ID'

const AccountButton: React.FC = () => {
  const { login, logout } = useAuth()
  const { onPresentConnectModal,onPresentAccountModal } = useWalletModal(login, logout)
  const { account, chainId } = useActiveWeb3React()
  const bxhCurrency = useCurrency(BXH.address)
  const useBxhPrice = useGetBXHBusdLpPrice()
  const useBxhBalance = useCurrencyBalance(account ?? undefined, bxhCurrency ?? undefined)
  let bxhBalance = '0'
  let bxhPrice = 0
  if(useBxhPrice) {
    bxhPrice = useBxhPrice
  }
  if(useBxhBalance){
    bxhBalance = useBxhBalance.toFixed(4)
  }
  // useEffect(() => {
  //   if(useBxhPrice) {
  //     setBxhPrice(useBxhPrice)
  //   }
  //   if(useBxhBalance){
  //     setBxhBalance(bxhBalance)
  //   }
  // }, [useBxhPrice,bxhBalance])
  // const toggleWalletModal = useWalletModalToggle()
  // const handleSignOutClick = useCallback(() => {
  //   window.localStorage.removeItem(CONNECTOR_STORAGE_ID)
  //   // unsetConnector()
  // }, [])
  const allTransactions = useAllTransactions()
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])
  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)

  const copy = () => {
    const input = document.createElement('input')
    input.setAttribute('readonly', 'readonly')
    input.setAttribute('value', account||"")
    document.body.appendChild(input)
    input.select()
    if (document.execCommand('copy')) {
      document.execCommand('copy')
    }
    document.body.removeChild(input)
  }
  return (
    <StyledAccountButton>
      {!account ? (
        <Button onClick={onPresentConnectModal}>
          <TranslatedText translationId={5656}>Unlock Wallet</TranslatedText>
        </Button>
        // <UnlockButton />
      ) : (
        <AccountCn>
          {/* <Account>
            <img src={require('../../assets/bxh/link.png').default} alt="" className="link" />
            <span className="text">{shortenAddress(account)}</span>
            <img src={userlogo} alt="" className="user-account" />
          </Account> */}
          {
            isMobile?(
              pending&&pending.length>0?
              (
                <Account>
                  <Pending>{pending.length}&nbsp;Pending</Pending>
                  <ConfirmedIcon>
                    <CustomLightSpinner src={require('../../assets/bxh/blue-loader.png').default} alt="loader" size='14px' />
                  </ConfirmedIcon>
                </Account>
              ):(
                <AccountMobile>
                  {/* <img src={require('../../assets/bxh/wallet.png').default} alt="" className="wallet" /> */}
                  <span className="wallet">{shortenAddress(account)}</span>
                </AccountMobile>
              )
            ):(
              <div>
                <Account>
                  {/* <span className="text">{shortenAddress(account)}</span> */}
                  <img src={require('../../assets/bxh/tesol.png').default} alt="" className="wallet" />
                </Account>
                {
                  pending&&pending.length>0?(
                    <PendingContent>
                        <Account>
                          <Pending>{pending.length}&nbsp;Pending</Pending>
                          <ConfirmedIcon>
                            <CustomLightSpinner src={require('../../assets/bxh/blue-loader.png').default} alt="loader" size='14px' />
                          </ConfirmedIcon>
                        </Account>
                    </PendingContent>
                  ):null
                }
              </div>
            )
          }
          <Modal className="modal">
            <ModalContent>
              <Content>
                <div className="title">Your wallet</div>
                <div className="subtitle">
                  <span>{shortenAddress(account)}</span>
                  <button type="button" onClick={copy}>
                    <img className="img" src={require('../../assets/bxh/copy.png').default} alt="" />
                  </button>
                </div>
              </Content>
              <Content>
                <div className="title">Your BXH Balance</div>
                <div className="money">{bxhBalance}</div>
                <div className="title usdt">=${numberDecimal(Number(bxhBalance)*bxhPrice)}</div>
                {account && chainId && (
                  <Link target='_blank' href={getBscScanLink(chainId, account, 'address')}>
                    View on OKLink
                  </Link>
                )}
              </Content>
              <div className="flex">
                <ButtonCustom onClick={onPresentAccountModal}>
                  <TranslatedText translationId={5704}>Sign out</TranslatedText>
                </ButtonCustom>
              </div>
            </ModalContent>
          </Modal>
        </AccountCn>
      )}
    </StyledAccountButton>
  )
}
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
export const Spinner = styled.img`
  animation: 2s ${rotate} linear infinite;
  width: 14px;
  height: 14px;
`
const PendingContent = styled.div`
  position: absolute;
  top: 60px;
  right: -20px;
`
const Pending = styled.div`
  color: ${props => props.theme.colors.primary};
`
const ConfirmedIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  height: 14px;
  width: 14px;
`
const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

const Button = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.backgroundAlt};
  border-radius: 100px;
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 36px;
  font-weight: 700;
  justify-content: center;
  outline: none;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.sm} {
    background: ${props => props.theme.colors.background};
  };
  &:hover {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 0.2);
  }
`
const Link = styled.a`
  display: block;
  margin-top: 20px;
  color: ${props => props.theme.colors.primary};
  font-size: 14px;
  line-height: 16px;
  font-weight: bolder;
  text-decoration: none;
`
const ButtonCustom = styled.div`
  margin: 12px 0;
  padding: 5px 12px;
  border: 1px solid #7f868f;
  color: ${props => props.theme.colors.text};
  text-align: center;
  border-radius: 24px;
  cursor: pointer;
`
const Content = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  font-size: 14px;
  line-height: 24px;
  font-weight: bolder;
  color: ${props => props.theme.colors.text};
  .title {
    color: ${props => props.theme.colors.text};
  }
  .money {
    font-size: 16px;
  }
  .usdt {
    font-size: 12px;
  }
  .subtitle {
    display: flex;
    justify-content: space-between;
    button {
      display: block;
      padding: 0px;
      width: 18px;
      height: 18px;
      border: none;
      border-radius: 0;
      background: transparent;
    },
    img {
      height: 18px;
    }
  }
  .img {
    cursor: pointer;
  }
`
const AccountCn = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    .modal {
      display: block;
    }
  }
`
const Account = styled.div`
  height: 24px;
  border-radius: 12px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  justify-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.text};
  background: ${props => props.theme.colors.backgroundDisabled};
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 40px;
    border-radius: 20px;
  };
  .user-account {
    width: 40px;
  }
  .link {
    width: 15px;
  }
  .wallet {
    margin: 0 5px;
    width: 15px;
    ${({ theme }) => theme.mediaQueries.sm} {
      width: 18px;
      margin: 0 1px;
    };
  }
`
const AccountMobile = styled.div`
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  justify-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.text};
  border: 1px solid hsla(0,0%,100%,.5);
  border-radius: 13px;
  background: transparent;
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 40px;
    border-radius: 20px;
    background: ${props => props.theme.colors.backgroundDisabled};
  };
  .wallet {
    height: 30px;
    line-height: 30px;
  }
`
const StyledAccountButton = styled.div`
  @media (max-width: 850px) {
    button {
      padding: 6px 10px;
      font-size: 12px;
      border-radius: 8px;
    }
  }
`
const Modal = styled.div`
  position: absolute;
  right: -60px;
  top: 40px;
  width: 300px;
  display: none;
  z-index: 100;
`
const ModalContent = styled.div`
  margin-top: 20px;
  padding: 4px 12px 12px 12px;
  padding-left: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
  background: ${props => props.theme.colors.backgroundAlt};
  box-shadow: 0px 4px 20px rgba(117, 117, 117, 0.1);
  border-radius: 12px;
  .flex {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`

export default AccountButton
