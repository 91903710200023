import { useEffect } from 'react'
import useGetBXHBusdLpPrice from 'utils/useGetBXHBusdLpPrice'

const useGetDocumentTitlePrice = () => {
  const bxhPriceBusd = useGetBXHBusdLpPrice()

  const bxhPriceBusdString =
    Number.isNaN(bxhPriceBusd) || bxhPriceBusd === 0 || !bxhPriceBusd
      ? ''
      : ` - $${bxhPriceBusd.toLocaleString(undefined, {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        })}`

  useEffect(() => {
    document.title = `BXHSwap${bxhPriceBusdString}`
  }, [bxhPriceBusdString])
}
export default useGetDocumentTitlePrice
