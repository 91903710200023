import { LanguageObject } from '../hooks/LanguageContext'
import { ZHHK, EN } from '../constants/localisation/languageCodes'

export function getNoticeUrl(selectedLanguage: LanguageObject): string {
    const cnUrl = 'https://bxh.gitbook.io/bxh/notice'
    const enUrl = 'https://bxh.gitbook.io/bxh/v/english/notice'
    if(!selectedLanguage){
        return enUrl;
    }
    const language = selectedLanguage.code
    if(language===ZHHK.code) {
        return cnUrl;
    }
    return enUrl;
}

export function getLangURLWithURL(selectedLanguage: LanguageObject, url: string): string {
    if(!selectedLanguage){
        return `${url}?lang=${EN.code}`
    }
    const language = selectedLanguage.code
    return `${url}?lang=${language}`
}

export function urlChangeLanguage(setSelectedLanguage): boolean {
    const url = window.location.hash; // 获取url中"#"符后的字串
    let isChange = true;
    if(url.indexOf(`lang=${ZHHK.code}`)>0){
        setSelectedLanguage(ZHHK)
    }else if(url.indexOf(`lang=${EN.code}`)>0){
        setSelectedLanguage(EN)
    }else{
        isChange = false;
    }
    return isChange;
}
