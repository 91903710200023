import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { NavLink, Link as HistoryLink } from 'react-router-dom'
import { IconButton, useModal, TuneIcon } from '@pancakeswap-libs/uikit'
import { ArrowLeft } from 'react-feather'
import { RowBetween } from 'components/Row'
import QuestionHelper from 'components/QuestionHelper'
import useI18n from 'hooks/useI18n'
import { useTranslation } from 'react-i18next'
import TranslatedText from '../TranslatedText'
import SettingsModal from '../PageHeader/SettingsModal'

const Tabs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`
const TabsSettings = styled(IconButton)`
  position: absolute;
  right: 30px;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textDisabled};
  font-size: 20px;
  

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
  }

  :hover,
  :focus {
    color: ${({ theme }) => theme.colors.textSubtle};
  }
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.colors.text};
`

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  const TranslateString = useI18n()
  const { t } = useTranslation()
  const [onPresentSettings] = useModal(<SettingsModal translateString={TranslateString} />)
  return (
    <Tabs style={{margin: '1rem 1rem 0'}}>
      <StyledNavLink id='swap-nav-link' to='/swap' isActive={() => active === 'swap'}>
        {t('BXH.swap')}
      </StyledNavLink>
      <StyledNavLink id='pool-nav-link' to='/pool' isActive={() => active === 'pool'}>
        {t('pool')}
      </StyledNavLink>
      {/* <TabsSettings style={{display:active==='swap'?'block':'none'}}>
        <Settings/>
      </TabsSettings> */}
      <TabsSettings style={{display:active==='swap'?'block':'none'}} variant="text" onClick={onPresentSettings} title={TranslateString(1200, 'Settings')}>
        <TuneIcon width="24px" color="currentColor" />
      </TabsSettings>
    </Tabs>
  )
}

export function FindPoolTabs() {
  const TranslateString = useI18n()
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>Import Pool</ActiveText>
        <QuestionHelper
          text={TranslateString(256, 'Use this tool to find pairs that do not automatically appear in the interface.')}
        />
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({ adding }: { adding: boolean }) {
  const TranslateString = useI18n()
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{adding ? TranslateString(258, 'Add') : TranslateString(260, 'Remove')} Liquidity</ActiveText>
        <QuestionHelper
          text={
            adding
              ? TranslateString(
                  264,
                  'When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.'
                )
              : TranslateString(
                  266,
                  'Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.'
                )
          }
        />
      </RowBetween>
    </Tabs>
  )
}
